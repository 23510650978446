import { jsx as t, jsxs as u } from "react/jsx-runtime";
import { forwardRef as M, useState as w, useEffect as N, useRef as S, useCallback as P } from "react";
import { useMediaQuery as U } from "@vgno/hooks";
import * as z from "@vgno/icons";
import { OpenExternal as G, Bullet as J, Cancel as Z, Vglive as K, ArrowLeft as Q, ArrowRight as Y } from "@vgno/icons";
import { isOngoing as R, toFriendly as X, toSlug as $, isPast as F, startOfDay as ee, classnames as te } from "@vgno/utils";
import { createPortal as ne } from "react-dom";
import './assets/SportsCalendar.css';const B = (l) => {
  const e = z[l.icon];
  return e ? /* @__PURE__ */ t(e, {}) : null;
}, se = "_event_tss8d_1", ie = "_eventDetails_tss8d_12", re = "_eventTime_tss8d_22", le = "_eventLive_tss8d_30", ae = "_eventLiveIcon_tss8d_34", oe = "_eventTitle_tss8d_38", ce = "_eventSubtitle_tss8d_47", de = "_eventDescription_tss8d_55", he = "_eventCoverage_tss8d_64", ue = "_eventCoverageLink_tss8d_69", me = "_eventChannelHeader_tss8d_79", ve = "_eventChannels_tss8d_89", _e = "_channel_tss8d_95", fe = "_channelIcon_tss8d_108", pe = "_channelImage_tss8d_115", ge = "_channelTitle_tss8d_122", we = "_calenderOpenLink_tss8d_136", Ce = "_eventCloseIcon_tss8d_143", c = {
  event: se,
  eventDetails: ie,
  eventTime: re,
  eventLive: le,
  eventLiveIcon: ae,
  eventTitle: oe,
  eventSubtitle: ce,
  eventDescription: de,
  eventCoverage: he,
  eventCoverageLink: ue,
  eventChannelHeader: me,
  eventChannels: ve,
  channel: _e,
  channelIcon: fe,
  channelImage: pe,
  channelTitle: ge,
  calenderOpenLink: we,
  eventCloseIcon: Ce
}, O = ({ logo: l, title: e, url: r }) => {
  const f = r ? "a" : "div", m = r ? { href: r } : {};
  return /* @__PURE__ */ u(f, { className: c.channel, ...m, children: [
    l && /* @__PURE__ */ t(
      "img",
      {
        alt: "",
        className: c.channelImage,
        sizes: "(min-width: 1024px) 256px, (min-width: 768px) 128px, 64px",
        srcSet: l.urls.map(({ url: d, width: h }) => `${d} ${h}w`).join(", ")
      }
    ),
    r ? /* @__PURE__ */ t(G, { className: c.channelIcon }) : null,
    /* @__PURE__ */ t("b", { className: c.channelTitle, children: e })
  ] });
}, W = M(
  ({ clickLocation: l, event: e, location: r, openDirection: f, setVisible: m }, d) => {
    const [h] = w(() => document.createElement("div"));
    N(() => (document.body.appendChild(h), () => {
      document.body.removeChild(h);
    }), [h]);
    const C = e.endsAt && R(e.eventStart || e.startsAt, e.endsAt);
    return ne(
      /* @__PURE__ */ u(
        "div",
        {
          className: c.event,
          ref: d,
          style: {
            left: `${f === "right" ? l.x : l.x - 380}px`,
            position: "absolute",
            top: `${((r == null ? void 0 : r.top) ?? 0) + ((r == null ? void 0 : r.height) ?? 0) + window.scrollY}px`,
            zIndex: 2
          },
          children: [
            /* @__PURE__ */ u("div", { className: c.eventDetails, children: [
              /* @__PURE__ */ t(B, { icon: e.sport.icon }),
              /* @__PURE__ */ u("div", { children: [
                /* @__PURE__ */ u("div", { style: { alignItems: "center", display: "flex" }, children: [
                  C && /* @__PURE__ */ t(J, { className: c.eventLiveIcon }),
                  /* @__PURE__ */ t(
                    "time",
                    {
                      className: `${c.eventTime} ${C && c.eventLive}`,
                      dateTime: new Date(
                        e.eventStart || e.startsAt
                      ).toUTCString(),
                      children: C ? "Nå" : X(e.eventStart || e.startsAt).replace(
                        "kl.",
                        e.eventStartEstimated ? "ca." : "kl."
                      )
                    }
                  )
                ] }),
                /* @__PURE__ */ t("h1", { className: c.eventTitle, children: e.title }),
                /* @__PURE__ */ t("p", { className: c.eventSubtitle, children: e.sport.title })
              ] }),
              /* @__PURE__ */ t(
                Z,
                {
                  className: c.eventCloseIcon,
                  onClick: () => m(!1)
                }
              )
            ] }),
            e.description && /* @__PURE__ */ t("div", { className: c.eventDescription, children: /* @__PURE__ */ t("p", { children: e.description }) }),
            e.liveCoverageUrl && /* @__PURE__ */ t("div", { className: c.eventCoverage, children: e.liveCoverageUrl.includes("live") ? /* @__PURE__ */ u(
              "a",
              {
                className: c.eventCoverageLink,
                href: e.liveCoverageUrl,
                children: [
                  /* @__PURE__ */ t(K, {}),
                  " Følg direkte på VG Live"
                ]
              }
            ) : /* @__PURE__ */ t(
              "a",
              {
                className: c.eventCoverageLink,
                href: e.liveCoverageUrl,
                children: "Følg direkte på VG"
              }
            ) }),
            /* @__PURE__ */ t("h3", { className: c.eventChannelHeader, children: "Sendes på" }),
            /* @__PURE__ */ u("div", { className: c.eventChannels, children: [
              e.channels.map((_) => /* @__PURE__ */ t(O, { ..._ }, _.id)),
              e.streams.map((_) => /* @__PURE__ */ t(O, { ..._ }, _.id))
            ] }),
            /* @__PURE__ */ t(
              "a",
              {
                className: c.calenderOpenLink,
                href: `https://tvguide.vg.no/sport/${$(e.title)}-${e._id}`,
                children: "Gå til VGs TV-Guide"
              }
            )
          ]
        }
      ),
      h
    );
  }
);
W.displayName = "SportsCalendarWidgetModal";
const Ne = async (l) => {
  const e = await fetch(l);
  if (!e.ok)
    throw new Error(e.statusText);
  return await e.json();
}, H = async ({ tags: l }) => {
  const e = new URL("https://api.vg.no/sportskalender/v1/events");
  l != null && l.length ? e.searchParams.set("tags", l == null ? void 0 : l.join("|")) : e.searchParams.set("highlight", "true");
  try {
    const r = await Ne(e.toString());
    return r.length ? r : null;
  } catch (r) {
    return console.error("Error fetching data:", r), null;
  }
}, ke = "_button_fltqn_4", be = "_channel_fltqn_30", Le = "_date_fltqn_39", De = "_datecontainer_fltqn_53", Se = "_eventlist_fltqn_59", xe = "_eventgroup_fltqn_79", ye = "_events_fltqn_85", Ie = "_event_fltqn_59", $e = "_link_fltqn_102", qe = "_graphic_fltqn_115", Ee = "_hidden_fltqn_124", Te = "_illustration_fltqn_134", Ae = "_meta_fltqn_144", Oe = "_scrolled_fltqn_148", Re = "_timestamp_fltqn_154", Be = "_title_fltqn_159", We = "_titlecontainer_fltqn_171", He = "_widget_fltqn_178", Ve = "_widgetWrapper_fltqn_197", i = {
  button: ke,
  channel: be,
  date: Le,
  datecontainer: De,
  eventlist: Se,
  eventgroup: xe,
  events: ye,
  event: Ie,
  link: $e,
  graphic: qe,
  hidden: Ee,
  illustration: Te,
  meta: Ae,
  scrolled: Oe,
  timestamp: Re,
  title: Be,
  titlecontainer: We,
  widget: He,
  widgetWrapper: Ve
}, je = ({
  children: l,
  event: e,
  ...r
}) => {
  const [f, m] = w(!1), [d, h] = w(null), [C, _] = w(), [x, k] = w("right"), [y, D] = w({
    x: 0,
    y: 0
  }), b = S(null), I = P((a) => {
    a && (_(a.getBoundingClientRect()), h(a));
  }, []);
  return N(() => {
    const a = (p) => {
      const L = d && !d.contains(p.target), n = b.current && b.current.contains(p.target);
      L && !n && m(!1);
    };
    return document.addEventListener("mousedown", a), () => {
      document.removeEventListener("mousedown", a);
    };
  }, [d]), N(() => {
    const a = () => {
      d && _(d.getBoundingClientRect());
    };
    return window.addEventListener("resize", a), () => {
      window.removeEventListener("resize", a);
    };
  }, [d]), N(() => {
    const a = (p) => {
      p.key === "Escape" && m(!1);
    };
    return document.addEventListener("keydown", a), () => {
      document.removeEventListener("keydown", a);
    };
  }, []), r.href ? /* @__PURE__ */ t("a", { ...r, children: l }) : /* @__PURE__ */ u("div", { ...r, ref: I, children: [
    /* @__PURE__ */ t(
      "div",
      {
        className: i.link,
        onClick: (a) => {
          if (f) return m(!1);
          if (!d) return;
          const { x: p, y: L } = a.nativeEvent, n = window == null ? void 0 : window.innerWidth;
          n && p < n / 2 ? k("right") : k("left"), _(d == null ? void 0 : d.getBoundingClientRect()), D({ x: p, y: L }), m(!0);
        },
        onKeyDown: (a) => {
          a.key === "Enter" && (window.location.href = `https://tvguide.vg.no/sport/${$(e.title)}-${e._id}`);
        },
        role: "button",
        tabIndex: 0,
        children: l
      }
    ),
    f && /* @__PURE__ */ t(
      W,
      {
        clickLocation: y,
        event: e,
        location: C,
        openDirection: x,
        ref: b,
        setVisible: m
      }
    )
  ] });
};
function Me({
  className: l,
  data: e,
  ...r
}) {
  const f = S(null), m = S(null), d = S(null), [h, C] = w(e), [_, x] = w(!0), [k, y] = w(!1), D = U(["(max-width: 580px)"]);
  N(() => {
    (async () => {
      const s = await H(r);
      s && C(s);
    })();
  }, []);
  function b({
    innerArray: n,
    innerIndex: s,
    outerArray: v,
    outerIndex: g
  }) {
    return g === 0 && s === 0 ? m : g === v.length - 1 && s === n.length - 1 ? d : null;
  }
  N(() => {
    const n = [
      m.current,
      d.current
    ].filter(Boolean);
    let s;
    const v = new IntersectionObserver(
      (g) => {
        g.forEach((o) => {
          o.target === m.current && (s = setTimeout(() => {
            clearTimeout(s), y(!o.isIntersecting);
          }, 500)), o.target === d.current && x(!o.isIntersecting);
        });
      },
      {
        root: f.current,
        threshold: [0.5]
      }
    );
    return n.forEach((g) => {
      v.observe(g);
    }), () => {
      n.forEach((g) => {
        v.unobserve(g);
      });
    };
  }, [h]);
  const I = (n) => {
    const s = /* @__PURE__ */ new Date();
    return s.setHours(0, 0, 0, 0), s.toDateString() === n.toDateString() ? "I dag" : (s.setDate(s.getDate() + 1), s.toDateString() === n.toDateString() ? "I morgen" : (s.setDate(s.getDate() + 5), n.setHours(0, 0, 0, 0), s.getTime() >= n.getTime() ? n.toLocaleDateString(
      ["nb-no", "da", "sv", "en-us", "en-gb"],
      {
        weekday: "long"
      }
    ) : n.toLocaleDateString(
      ["nb-no", "da", "sv", "en-us", "en-gb"],
      {
        day: "numeric",
        month: "short"
      }
    )));
  }, a = (n) => {
    const s = f.current;
    if (!s)
      return null;
    s.scrollLeft += 200 * (n === "right" ? -1 : 1);
  };
  if (!h || !h.length) return null;
  const p = h == null ? void 0 : h.filter((n) => n.endsAt && !F(new Date(n.endsAt))).reduce(
    (n, s) => {
      const v = ee(new Date(s.startsAt)).toJSON();
      return n[v] ?? (n[v] = {
        date: v,
        events: []
      }), n[v].events.push(s), n;
    },
    {}
  );
  if (!Object.keys(p).length)
    return null;
  const L = (n) => n.endsAt && R(n.eventStart || n.startsAt, n.endsAt);
  return /* @__PURE__ */ t(
    "section",
    {
      className: te(i.widget, "grouped", l),
      "data-track-element-type": "SportsCalendarWidget",
      id: "sports-calendar-widget",
      children: /* @__PURE__ */ u("div", { className: i.widgetWrapper, children: [
        /* @__PURE__ */ t(
          "ul",
          {
            className: `${i.eventlist} ${k && i.scrolled}`,
            ref: f,
            children: Object.values(p).map(
              ({ date: n, events: s }, v, g) => /* @__PURE__ */ u("li", { className: i.eventgroup, children: [
                /* @__PURE__ */ t("div", { className: i.datecontainer, children: /* @__PURE__ */ u("div", { className: i.illustration, children: [
                  /* @__PURE__ */ t(
                    "svg",
                    {
                      className: i.graphic,
                      fill: "none",
                      height: "8",
                      viewBox: "0 0 8 8",
                      width: "8",
                      xmlns: "http://www.w3.org/2000/svg",
                      children: /* @__PURE__ */ t(
                        "path",
                        {
                          d: "M0 0H8C3.58172 0 0 3.58172 0 8V0Z",
                          fill: "currentColor"
                        }
                      )
                    }
                  ),
                  /* @__PURE__ */ t(
                    "svg",
                    {
                      className: i.graphic,
                      fill: "none",
                      height: "8",
                      viewBox: "0 0 8 8",
                      width: "8",
                      xmlns: "http://www.w3.org/2000/svg",
                      children: /* @__PURE__ */ t(
                        "path",
                        {
                          d: "M0 0C0 4.41827 3.58172 8 8 8H0V0Z",
                          fill: "currentColor"
                        }
                      )
                    }
                  ),
                  /* @__PURE__ */ t("h3", { className: i.date, children: I(new Date(n)) })
                ] }) }),
                /* @__PURE__ */ t("ul", { className: i.events, children: s.map((o, V, j) => {
                  var q, E, T, A;
                  return /* @__PURE__ */ t(
                    "li",
                    {
                      className: i.event,
                      id: o._id,
                      ref: b({
                        innerArray: j,
                        innerIndex: V,
                        outerArray: g,
                        outerIndex: v
                      }),
                      role: "presentation",
                      children: /* @__PURE__ */ u(
                        je,
                        {
                          className: i.link,
                          "data-track-name": o.title,
                          event: o,
                          href: D ? `https://tvguide.vg.no/sport/${$(o.title)}-${o._id}` : void 0,
                          role: D ? "link" : "button",
                          children: [
                            /* @__PURE__ */ u("span", { className: i.meta, children: [
                              /* @__PURE__ */ t("time", { className: i.timestamp, children: L(o) ? "Nå" : new Date(
                                o.eventStart || o.startsAt
                              ).toLocaleTimeString(
                                ["nb-no", "da", "sv", "en-us", "en-gb"],
                                {
                                  hour: "numeric",
                                  minute: "numeric"
                                }
                              ) }),
                              /* @__PURE__ */ t("span", { className: i.channel, children: ((E = (q = o.channels) == null ? void 0 : q[0]) == null ? void 0 : E.title) || ((A = (T = o.streams) == null ? void 0 : T[0]) == null ? void 0 : A.title) })
                            ] }),
                            /* @__PURE__ */ u("div", { className: i.titlecontainer, children: [
                              /* @__PURE__ */ u("h4", { className: i.title, children: [
                                o.sport.title,
                                ": ",
                                o.title
                              ] }),
                              /* @__PURE__ */ t(B, { icon: o.sport.icon })
                            ] })
                          ]
                        }
                      )
                    },
                    o._id
                  );
                }) })
              ] }, n)
            )
          }
        ),
        /* @__PURE__ */ t(
          "button",
          {
            className: `${i.button} ${!k && i.hidden}`,
            onClick: () => a("right"),
            type: "button",
            children: /* @__PURE__ */ t(Q, {})
          }
        ),
        /* @__PURE__ */ t(
          "button",
          {
            className: `${i.button} ${!_ && i.hidden}`,
            onClick: () => a("left"),
            type: "button",
            children: /* @__PURE__ */ t(Y, {})
          }
        )
      ] })
    }
  );
}
const Ke = {
  Component: Me,
  fetcher: H
};
export {
  Ke as default
};
